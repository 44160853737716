<template>
  <div class="tab-container">
    <overlay-loader :loading="$apollo.queries.ownerUsers.loading"/>
    <hcc-table
      :actualPage="page"
      :rowsPerPage="10"
      :totalPages="total"
      :pagination="total > 10"
      :columns="userColumns"
      :rows="ownerUsers"
      @search="searchUser"
      @add="addNewUser"
      @edit="editUser"
      @delete="deleteUser"
      @pageChanged="pageChanged"
    />
    <owner-actions
      :edit="isEdit"
      :user="infoUser"
    />
    <hcc-confirmation />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import deleteUser from '@/graphql/mutations/deleteUser.gql';
import listCompanyUsers from '@/graphql/queries/users/listByCompanyId.gql';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import EventBus from '@/eventBus';
import errorHandler from '@/utils/error.handler';
import { UPDATE_USERS_OWNERS } from '@/eventTypes';

export default {
  data() {
    return {
      page: 1,
      text: null,
      infoUser: {},
      total: 0,
      isEdit: false,
      role: {},
    };
  },
  components: {
    OverlayLoader,
    OwnerActions: () => import('@/components/users/OwnerActions.vue'),
    HccTable: () => import('@/components/shared/HccTable/index.vue'),
    HccConfirmation: () => import('@/components/shared/HccConfirmation/index.vue'),
  },
  created() {
    EventBus.$on(UPDATE_USERS_OWNERS, () => {
      this.$apollo.queries.ownerUsers.refresh();
    });
  },
  computed: {
    userColumns() {
      return [{
        label: this.$t('common.name'),
        field: 'name',
      }, {
        label: this.$t('common.email'),
        field: 'email',
      }];
    },
    emptyRows() {
      return this.total === 0;
    },
  },
  apollo: {
    ownerUsers() {
      return {
        query: gql`${listCompanyUsers}`,
        variables() {
          return {
            roles: ['owner'],
            page: this.page,
            perPage: 10,
            text: this.text,
          };
        },
        fetchPolicy: 'network-only',
        update({ listCompanyUsers: usersPage }) {
          this.total = usersPage.total;
          return usersPage.users;
        },
        error(err) {
          errorHandler.logErrors(err);
          this.$toasted.global.error(this.$t('alerts.unexpectedError'));
        },
      };
    },
  },
  methods: {
    pageChanged(page) {
      this.page = page;
    },
    deleteUser(props) {
      this.$modal.show('confirmation', {
        title: 'Eliminar Usuario',
        description: this.$t('configuration.confirmation'),
        variant: 'error',
        confirm: async () => {
          await this.$apollo.mutate({
            mutation: deleteUser,
            variables: {
              userId: props.id,
            },
          });
          this.page = 1;
          this.$apollo.queries.ownerUsers.refresh();
        },
      });
    },
    editUser(props) {
      this.isEdit = true;
      this.infoUser = props;
      this.$modal.show('Usuario');
    },
    addNewUser() {
      this.isEdit = false;
      this.$modal.show('Usuario');
    },
    async searchUser({ value }) {
      this.text = value;
    },
  },
};
</script>

<style scoped lang="scss">
  .tab-container{
    position: relative;
  }
</style>
